import React from 'react';
import styled from '@emotion/styled';
import { colors, respondFrom, breakpoints, css, mixins, dimensions } from '@styles';
import Badge from '@components/common/Badge';
import BadgeRecommended from '@components/common/BadgeRecommended';
import ButtonCart from '@components/common/ButtonCart';
import { Tariff, TariffPrice } from '@content/types/tariff';
import { ConfiguratorBasket, Funding } from '@store/types';

import { ConfiguratorActions } from '@store/actions';
import * as utils from '@utils';
import TariffOptionAccordion from '@components/configurator/tariff/TariffOptionAccordion';
import { LocationDependent, Stylable } from '@content/types/general';
import { ConfiguratorSettings } from '@content/types/configuratorSettings';

const Wrapper = styled.div`
  height: 100%;
  font-size: ${dimensions.fontSize.small}px;
  line-height: normal;
  position: relative;
  overflow: hidden;
  ${mixins.transitionDefault};
  ${mixins.borderOnHover};
  ${mixins.borderWhenActive};

  &:hover {
    cursor: auto !important;
    &:after {
      display: none;
    }
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: solid 1px ${colors.gray};
  }

  ${respondFrom(
    breakpoints.md,
    css`
      display: flex;
      flex-direction: column;
    `
  )};

  > div {
    position: relative;
  }

  &.disabled {
    pointer-events: none;

    > div {
      opacity: 0.25;
    }
  }
`;

const Header = styled.div<StyledProps>`
  background-color: ${colors.grayNurse};
  position: relative;
  overflow: hidden;
  padding: ${props => (props.popular ? '30px 20px 10px' : '30px 20px 10px')};

  /* cover the border */
  margin: ${props => (props.popular ? '-1px -1px 0 -1px' : '0')};

  ${respondFrom(
    breakpoints.md,
    css`
      padding: 30px 20px;
    `
  )};

  h2 {
    font-size: 25px;
    font-weight: 100;

    ${respondFrom(
      breakpoints.md,
      css`
        font-size: 35px;
      `
    )};
  }

  h3 {
    font-size: 30px;
    font-weight: 500;
    margin: 0;
  }

  p {
    margin-top: 30px;

    ${respondFrom(
      breakpoints.md,
      css`
        min-height: 80px;
      `
    )};

    ${respondFrom(
      breakpoints.lg,
      css`
        min-height: 100px;
      `
    )};

    ${respondFrom(
      breakpoints.xxl,
      css`
        min-height: 120px;
      `
    )};

    ${respondFrom(
      breakpoints.desktopGoToTopMax,
      css`
        min-height: 8em;
      `
    )};

    ${respondFrom(
      1650,
      css`
        min-height: 6em;
      `
    )};
  }

  &.popular {
    background-color: ${colors.text.default};

    h2,
    p,
    h3,
    span {
      color: ${colors.white};
    }
  }
`;

const ContentWrapper = styled.div`
  ${respondFrom(
    breakpoints.md,
    css`
      flex-grow: 1;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
    `
  )};
`;

const StyledTariffOptionAccordion = styled(TariffOptionAccordion)`
  ${respondFrom(
    breakpoints.md,
    css`
      flex-grow: 1;
    `
  )};
`;

const TariffErrorMessage = styled.div`
  display: none;
  position: absolute !important;
  opacity: 1 !important;
  top: 0;
  bottom: 0;
  left: 20px;
  right: 20px;
  margin: auto;
  height: max-content;
  width: auto;
  align-items: center;
  justify-content: center;

  p {
    padding: 25px;
    margin: 0;
    font-size: ${dimensions.fontSize.small}px;
    font-weight: 300;
    border: 1px solid ${colors.gray};
    background-color: ${colors.grayNurse};
  }

  &.is-visible {
    display: flex;
  }
`;

const BadgeRecommendedStyled = styled.div`
  margin: -1px -1px 0;

  position: absolute !important;
  top: -25px;
  left: 0;
  right: 0;

  ${respondFrom(
    breakpoints.md,
    css`
      top: -30px;
      left: -1px;
      right: -1px;
    `
  )};
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${respondFrom(
  breakpoints.md,
    css`
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    `
  )};
`;

const Currency = styled.div``;

const StyledAdditionalCost = styled.span`
  display: block;
  margin-top: 15px;
  font-size: 14px;
`;

const ButtonWrapper = styled.div`
  flex: 1;
  ${respondFrom(
  breakpoints.md,
    css`
      display: flex;
      justify-content: flex-end;
      margin-left: 20px;
    `
  )};
`;

interface TariffOptionProps extends LocationDependent, Stylable {
  tariff: Tariff;
  isSelected: boolean;
  configuratorActions: typeof ConfiguratorActions;
  disabled?: boolean;
  iconPlus?: boolean;
  pageSettings: ConfiguratorSettings;
  langcode: string;

  basket: ConfiguratorBasket;
  allFundings: Funding[];
  tariffPrice: TariffPrice;
  showAdditionalCost?: boolean;
}

interface StyledProps {
  popular: boolean;
}

class TariffOption extends React.Component<TariffOptionProps> {
  toggleInCart() {
    !this.props.isSelected
      ? this.props.configuratorActions.basketSetTariff(this.props.tariff)
      : null;

    // TODO: do we want to unset?
    // this.props.configuratorActions.basketSetTariff(null);
  }

  render() {
    const {
      tariff,
      isSelected,
      disabled,
      iconPlus,
      className,
      location,
      pageSettings,
      langcode,
      tariffPrice,
      showAdditionalCost
    } = this.props;

    return (
      <Wrapper
        className={`
          ${isSelected && 'active'}
          ${disabled && 'disabled'}
      `}
      >
        {utils.setRecommendedBadge(location, tariff.recommended) && (
          <BadgeRecommendedStyled>
            <BadgeRecommended text={pageSettings[langcode].field_proste_pola[89]} />
          </BadgeRecommendedStyled>
        )}
        <Header
          className={`
            ${tariff.popular === true ? 'popular' : ''}
          `}
          popular={tariff.popular}
        >
          <h2>{tariff.name}</h2>
          <p>{tariff.description}</p>
          <HeaderWrapper>
            {
              tariffPrice &&
              <Currency>
                <h3>{utils.formatCurrency(tariffPrice.iposFee + tariffPrice.locationFee)}</h3>
                <span>{pageSettings[langcode].field_proste_pola[96]}</span>
              </Currency>
            }
            <ButtonWrapper>
              <ButtonCart
                color="primary"
                size="full"
                title={
                  iconPlus
                    ? pageSettings[langcode].field_proste_pola[91]
                    : isSelected ?
                    pageSettings[langcode].field_proste_pola[129] :
                    pageSettings[langcode].field_proste_pola[32]
                }
                action={this.toggleInCart.bind(this)}
                choiceImg={'addToCart'}
                inactive={isSelected}
                iconPlus={iconPlus}
                hideIcon
              />
            </ButtonWrapper>
          </HeaderWrapper>
          {(this.props.basket.products.length >= 2 || showAdditionalCost) && (
            <StyledAdditionalCost>
              <strong>+ {utils.formatCurrency(tariffPrice.iposFee)}</strong>{' '}
              {pageSettings[langcode].field_proste_pola[181]}
            </StyledAdditionalCost>
          )}
          {tariff.popular && <Badge text={pageSettings[langcode].field_proste_pola[94]} />}
        </Header>
        <ContentWrapper>
          <StyledTariffOptionAccordion profits={tariff.profits} {...this.props} />
        </ContentWrapper>
        <TariffErrorMessage className={disabled ? 'is-visible' : ''}>
          <p>{pageSettings[langcode].field_proste_pola[46]}</p>
        </TariffErrorMessage>
      </Wrapper>
    );
  }
}

export default TariffOption;
